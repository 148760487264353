table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  
}

th, td {
  text-align: center;
  padding: 8px;
  white-space: nowrap;
}

